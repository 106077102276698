import React from "react";
import { Provider } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import { setMediaType } from "../../features/gameSlice"; // Import the action
import "./Home.css";
import Image from "react-bootstrap/Image";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleChangeMediaType = (mediaType) => {
    dispatch(setMediaType(mediaType));
    navigate("/game"); // Navigate to the home page
  };

  return (
    <div className="home-container">
      {" "}
      {/* Apply styles to the container */}
      <h1>Welcome to GuessQuest!</h1>
      <Image
        src="https://i.imgur.com/5zs7u4Y.jpeg/800x200"
        fluid
        style={{ width: "70%" }}
      />
      <p>Please choose your media below.</p>
      <div className="media-type-buttons">
        <button onClick={() => handleChangeMediaType("movies")}>Movies</button>
        <button onClick={() => handleChangeMediaType("tv_shows")}>
          TV Shows
        </button>
        <button onClick={() => handleChangeMediaType("games")}>Games</button>
      </div>
    </div>
  );
};

export default Home;
