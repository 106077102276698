import React from "react";
import PageContent from "./PageContent";
import ContactForm from "./ContactForm";

const ContactUs = () => {
  return (
    <PageContent>
      <h1>Contact Us</h1>
      <p>This is the Contact Us page.</p>
      <ContactForm />
    </PageContent>
  );
};

export default ContactUs;
