import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  nextLevel,
  preloadData,
  resetFailedGuesses,
  setNavigateToHome,
} from "../../features/gameSlice";
import ErrorMessage from "../common/ErrorMessage";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import GuessInput from "./GuessInput"; // Import GuessInput from the same folder
import GameImage from "./GameImage"; // Import GameImage from the same folder
import "./Game.css";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import GameModal from "./GameModal";
import setModalState from "./../../features/uiSlice";
import VictoryAnimation from "./Victory";

const Game = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const modalContent = useSelector((state) => state.ui.modalMessage);
  const navigateToHome = useSelector((state) => state.game.navigateToHome);

  const [victory, setVictory] = useState(false);

  // Trigger the victory state based on your game logic
  useEffect(() => {
    if (navigateToHome) {
      navigate("/");
      dispatch(setNavigateToHome(false)); // Reset the flag
    }
  }, [navigateToHome, navigate, dispatch]);

  const handleClose = () => {
    dispatch(setModalState({ show: false, message: "" }));
  };

  const { currentMediaType, currentLevel, levels } = useSelector(
    (state) => state.game
  );
  const totalLevels = levels[currentMediaType].length;

  return (
    <Container
      maxWidth="md"
      sx={{
        bgcolor: "#343541",
        height: "100vh",
        color: "white",
        paddingTop: "1rem",
      }}
    >
      <Box sx={{ my: 4 }}>
        <h4>Guess The Game</h4>
        <GameImage />
        <GuessInput />
        <GameModal
          show={showModal}
          handleClose={handleClose}
          modalContent={modalContent}
        />
        {/* Add any other components you need here */}
      </Box>
    </Container>
  );
};

export default Game;
