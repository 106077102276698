import React from "react";
import { useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { Image } from "react-bootstrap";
import "./GameImage.css";

const GameImage = () => {
  const { currentMediaType, currentLevel, levels } = useSelector(
    (state) => state.game
  );

  const currentImages = levels[currentMediaType][currentLevel].images;

  return (
    <div style={{ zIndex: 1 }}>
      <Carousel interval={null} slide={false}>
        {currentImages.map((imgSrc, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={imgSrc}
              alt={`Slide ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default GameImage;
