import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Button, Modal } from "react-bootstrap"; // Import Modal from react-bootstrap
import {
  nextLevel,
  submitUserGuess,
  failedGuess,
  preloadData,
  resetFailedGuesses,
} from "../../features/gameSlice";
import { setShowSuccessScreen } from "../../features/uiSlice";

const GuessInput = () => {
  const placeholder = "Enter your guess";
  const [guess, setGuess] = useState("");
  const [show, setShow] = useState(false); // State to control the visibility of the modal
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentLevel, levels, currentMediaType, failedGuesses, submitGuess } =
    useSelector((state) => state.game);
  const { isCorrect, currentGuess } = useSelector((state) => state.game);

  const totalLevels = levels[currentMediaType].length;
  const answer = levels[currentMediaType][currentLevel].correctGuess;

  useEffect(() => {
    return () => {
      dispatch(resetFailedGuesses());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isCorrect || currentGuess === 5) {
      setShow(true); // This will show the modal with the correct answer or failed attempt message
    }
  }, [isCorrect, currentGuess]);

  // Inside your component
  useEffect(() => {
    if (isCorrect && currentLevel === totalLevels) {
      navigate("/"); // Navigate home if the game is finished
    }
  }, [isCorrect, currentLevel, totalLevels, navigate]);

  const handleChange = (e) => {
    setGuess(e.target.value);
  };

  // Inside your component
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(submitUserGuess(guess));
    setGuess("");
  };

  const handleClose = () => setShow(false); // Function to close the modal

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formGuessInput">
          <Form.Label>Attempt {currentGuess}/5</Form.Label>{" "}
          {/* Display Attempt x/5 */}
          <Form.Control
            type="text"
            placeholder={placeholder}
            value={guess}
            onChange={handleChange}
          />
        </Form.Group>
        <Button type="submit">Submit Guess</Button>
      </Form>
      <div className="failed-guesses">
        <h3>Failed Guesses:</h3>
        <ul>
          {failedGuesses.map((guess, index) => (
            <li key={index}>{guess}</li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default GuessInput;
