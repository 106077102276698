// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import gameReducer from "../features/gameSlice";
import uiReducer from "../features/uiSlice";
import authReducer from "../features/authSlice";

export const store = configureStore({
  reducer: {
    game: gameReducer,
    ui: uiReducer,
    auth: authReducer,
    // other reducers...
  },
});

export default store;
