// App.js
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom"; // Use BrowserRouter for routing
import "bootstrap/dist/css/bootstrap.css";
import "./assets/App.css"; // Fix: Add missing quotes around the import path
import store from "./redux/store"; // Ensure this path matches the location of your Redux store
import Layout from "./components/common/Layout";
import Header from "./components/common/Header";
import ErrorMessage from "./components/common/ErrorMessage";
import AppRouter from "./AppRouter";
import VictoryAnimation from "./components/game/Victory";
import PageContent from "./components/pages/PageContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#570992",
    },
    background: {
      default: "#343541",
      paper: "#424242",
    },
    text: {
      primary: "#570992",
    },
  },
  // You can also customize typography, breakpoints, and other theme properties
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Layout>
          <VictoryAnimation />
          <AppRouter />
          <PageContent>{/* Main content goes here */}</PageContent>
        </Layout>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
