// Leaderboard.js

import React from "react";
import PageContent from "./PageContent";
import "./Leaderboard.css";

const Leaderboard = () => {
  // Mock data
  const leaderboardData = [
    { name: "Player 1", score: 500 },
    { name: "Player 2", score: 450 },
    { name: "Player 3", score: 400 },
    { name: "Player 4", score: 300 },
    { name: "Player 5", score: 200 },
    { name: "Player 6", score: 200 },
    { name: "Player 7", score: 250 },
    { name: "Player 8", score: 100 },
    { name: "Player 9", score: 100 },
    { name: "Player 10", score: 100 },
  ];

  return (
    <div className="leaderboard-container">
      <PageContent>
        <h1>Leaderboard</h1>
        {/* Leaderboard content */}
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>High Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.map((entry, index) => (
              <tr
                key={index}
                className={
                  index % 3 === 0
                    ? "purple"
                    : index % 3 === 1
                    ? "grey"
                    : "white"
                }
              >
                <td>{entry.name}</td>
                <td>{entry.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </PageContent>
    </div>
  );
};

export default Leaderboard;
