import React from "react";
import PageContent from "./PageContent";

const AboutUs = () => {
  return (
    <PageContent>
      <h1>About Us</h1>
      <p>This is the About Us page.</p>
    </PageContent>
  );
};

export default AboutUs;
