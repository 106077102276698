import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

// // Define a thunk for loading the next level
// export const loadNextLevel = createAsyncThunk(
//   "game/loadNextLevel",
//   async (levelId, { rejectWithValue }) => {
//     try {
//       // Replace this with the actual API call or other async operation
//       const response = await fetch(`/api/levels/${levelId}`);
//       const data = await response.json();
//       return data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

import axios from "axios";
import { setModalState, setVictoryAnimation } from "./uiSlice";

// Async thunk for submitting a user guess
export const submitUserGuess = createAsyncThunk(
  "game/submitUserGuess",
  async (guess, { getState, dispatch, rejectWithValue }) => {
    try {
      const { game } = getState();
      const currentLevelData =
        game.levels[game.currentMediaType][game.currentLevel];
      if (guess.toLowerCase() === currentLevelData.correctGuess.toLowerCase()) {
        // Guess is correct
        if (game.currentLevel < game.totalLevels) {
          // Not the last level, proceed to next level
          dispatch(
            setModalState({
              show: true,
              message: "Correct! You've guessed the right answer.",
            })
          );
          // Here you might also dispatch an action to fetch the next level's data
          // Example: dispatch(preloadNextLevelData());
        } else {
          // Last level, end the game
          // Here you might dispatch an action to navigate to a success screen
          // Example: dispatch(navigateToSuccessScreen());
          if (game.isCorrect && game.currentLevel >= game.totalLevels) {
            dispatch(setVictoryAnimation(true));
            setTimeout(() => {
              dispatch(setNavigateToHome(true));
            }, 5000); // 5-second dela
          }
        }
        dispatch(
          setModalState({
            show: true,
            message: "Correct! You've guessed the right answer.",
          })
        ); // Guess is correct
      } else {
        if (game.currentGuess >= 5) {
          // Max attempts reached
          dispatch(
            setModalState({
              show: true,
              message:
                "Sorry, that's not correct. The correct answer was: " +
                currentLevelData.correctGuess +
                ". Better luck next time :)",
            })
          );

          // Here you might dispatch an action to show the correct answer modal
          // Example: dispatch(showCorrectAnswerModal());
          return rejectWithValue("maxAttemptsReached");
        } else {
          dispatch(failedGuess(guess));
        }
        return false; // Guess is incorrect
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const gameSlice = createSlice({
  name: "game",
  initialState: {
    currentLevel: 0, // Zero Indexed
    status: "idle", // idle, loading, succeeded, failed
    error: null,
    navigateToHome: false, // Error code or message
    currentMediaType: "movies",
    gameCompleted: false,
    currentGuess: 1,
    failedGuesses: [],
    isCorrect: false,
    currentImageLink: "",
    answer: "",
    levels: {
      games: [
        {
          images: [
            "https://i.imgur.com/eVLZXif.png",
            "https://i.imgur.com/pcB4LWu.png",
            "https://i.imgur.com/Vpt4T0O.png",
            "https://i.imgur.com/khpcLNo.jpeg",
            "https://i.imgur.com/WIVIrCJ.png",
          ],
          correctGuess: "Cyberpunk 2077",
        },
        {
          images: [
            "https://i.imgur.com/lezBfZ0.jpeg",
            "https://i.imgur.com/NTD5niW.jpeg",
            "https://i.imgur.com/zRKUEaN.jpeg",
            "https://i.imgur.com/eZEcWUD.png",
            "https://i.imgur.com/jcF52QF.jpeg",
          ],
          correctGuess: "Pathfinder",
        },
        // Add more levels here if needed
        // ... other movie levels
      ],
      tv_shows: [
        {
          id: 1,
          images: [
            "https://i.imgur.com/Bpv4PAL.png",
            "https://i.imgur.com/viBdKLC.png",
            "https://i.imgur.com/ynhMlAs.jpeg",
            "https://i.imgur.com/HT6Dp7D.jpeg",
            "https://i.imgur.com/WIVIrCJ.png",
          ],
          correctGuess: "Doctor Who",
        },
        // ... other TV show levels
      ],
      movies: [
        {
          id: 1,
          images: [
            "https://i.imgur.com/eVLZXif.png",
            "https://i.imgur.com/pcB4LWu.png",
            "https://i.imgur.com/Vpt4T0O.png",
            "https://i.imgur.com/khpcLNo.jpeg",
            "https://i.imgur.com/WIVIrCJ.png",
          ],
          correctGuess: "answer1",
        },
        // ... other game levels
      ],
    },
  },
  reducers: {
    setNavigateToHome: (state, action) => {
      state.navigateToHome = action.payload;
    },
    submitGuess: (state, action) => {
      const guess = action.payload;
      const currentLevelData =
        state.levels[state.currentMediaType][state.currentLevel];

      const isCorrect =
        currentLevelData.correctGuess.toLowerCase() === guess.toLowerCase();

      if (isCorrect) {
        state.isCorrect = true;
        // Dispatch the nextLevel action to move to the next level
      } else {
        state.currentGuess += 1;
        state.isCorrect = false;
      }
    },

    setMediaType: (state, action) => {
      state.currentMediaType = action.payload;
      state.currentLevel = 0;
      state.currentGuess = 1; // Reset level when changing media type
    },

    preloadData: (state) => {
      const currentLevelData =
        state.levels[state.currentMediaType][state.currentLevel];
      state.currentImageLink = currentLevelData.images[0];
      state.answer = currentLevelData.correctGuess;
    },
    nextLevel: (state) => {
      const totalLevels = state.levels[state.currentMediaType].length;
      if (state.currentLevel < totalLevels - 1) {
        // Increment currentLevel if not last level
        state.currentLevel += 1;
      } else {
        // User has completed the last level
        state.gameCompleted = true; // You might want to add a new state property for this
      }
      // Reset other state variables
      state.currentGuess = 0;
      state.isCorrect = false;
      state.failedGuesses = [];
    },
    failedGuess: (state, action) => {
      state.failedGuesses.push(action.payload);
      state.currentGuess += 1;
      state.isCorrect = false;
    },
    resetFailedGuesses: (state) => {
      state.failedGuesses = [];
      state.currentGuess = 0;
      state.isCorrect = false;
    },
    // nextLevel: (state) => {
    //   state.currentLevel += 1;
    //   state.currentGuess = 0;
    //   state.isCorrect = false;
    //   state.failedGuesses = [];
    // },

    // failedGuess: (state, action) => {
    //   state.failedGuesses.push(action.payload);
    //   state.currentGuess += 1;
    //   state.isCorrect = false;
    // },

    // resetFailedGuesses: (state) => {
    //   state.failedGuesses = [];
    // },
  },
  extraReducers: {
    [submitUserGuess.pending]: (state) => {
      state.status = "loading";
    },
    [submitUserGuess.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [submitUserGuess.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    // other extraReducers...
  },
});

export const {
  submitGuess,
  nextLevel,
  setMediaType,
  preloadData,
  failedGuess,
  resetFailedGuesses,
  setNavigateToHome,
} = gameSlice.actions;

export default gameSlice.reducer;
