import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import PageContent from "./PageContent";
import "./Login.css"; // Import your Login.css file for styling

const Login = () => {
  return (
    <PageContent>
      <h1>Welcome back</h1>
      <form className="login-form">
        <label>
          Username:
          <input type="text" placeholder="Enter your username" />
        </label>
        <label>
          Password:
          <input type="password" placeholder="Enter your password" />
        </label>
        <button type="submit">Login</button>
      </form>
      <p>
        Don't have an account? <Link to="/signup">Sign up</Link>
      </p>
    </PageContent>
  );
};

export default Login;
