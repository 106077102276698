import React from "react";
import PageContent from "./PageContent";

const HowToPlay = () => {
  return (
    <PageContent>
      <h1>How to Play</h1>
      {/* Add content explaining how to play */}
    </PageContent>
  );
};

export default HowToPlay;
