// VictoryAnimation.js
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";
import { setVictoryAnimation } from "../../features/uiSlice";

// VictoryAnimation.js or in a separate file for styled components
import styled from "@emotion/styled";
import { fadeIn } from "../utils/animations"; // Adjust the import path as needed

export const AnimatedDialogContent = styled(DialogContent)`
  animation: ${fadeIn} 1s ease-out;
`;

const VictoryAnimation = () => {
  const dispatch = useDispatch();
  const showVictoryAnimation = useSelector(
    (state) => state.ui.showVictoryAnimation
  );

  const handleClose = () => {
    dispatch(setVictoryAnimation(false));
  };

  return (
    <Dialog open={showVictoryAnimation} onClose={handleClose}>
      <AnimatedDialogContent>
        <Typography variant="h4" color="primary">
          Thank you for playing!
        </Typography>
      </AnimatedDialogContent>
    </Dialog>
  );
};

export default VictoryAnimation;
