import React from "react";
import { Link } from "react-router-dom";
import PageContent from "./PageContent";
import "./SignUp.css";

const Signup = () => {
  return (
    <PageContent>
      <h1>Create an account</h1>
      <form className="signup-form">
        <label>
          Username:
          <input type="text" placeholder="Enter your Username" />
        </label>
        <label>
          Password:
          <input type="password" placeholder="Enter your password" />
        </label>
        <button type="submit">Sign up</button>
      </form>
      <p>
        Already have an account? <Link to="/login">Login</Link>
      </p>
    </PageContent>
  );
};

export default Signup;
